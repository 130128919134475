import Navbar from './Navbar';

export default function Login() {
    return(
        <>


        <center><h1>Login</h1></center>

        <Navbar />
        
        
        
        </>
    )
}