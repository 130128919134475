import React from 'react';
import logo from './img/chatgpt1.png';
import Home from './Home';

const Navbar = ({ handleSearch }) => {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
            <img src={logo} className='img' />
                <a className="navbar-brand" href="/">Chatgpt</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="/">Home</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/Contact">Contact</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/Gallery">Gallery</a>
                            </li>
                            <li className="nav-item">
                            <a className="nav-link" href="/Register">Register</a>
                            </li>
                            <li className="nav-item">
                            <a className="nav-link" href="/Login">Login</a>
                            </li>
                            <li className="nav-item">
                            <a className="nav-link" href="/About">About</a>
                            </li>
                            <li className="nav-item">
                            <a className="nav-link" href="/Threeone">Threeone</a>
                            </li>
                    </ul>
                    <form className="d-flex">
                        <input
                            className="form-control me-2"
                            type="search"
                            placeholder="Search"
                            aria-label="Search"
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                        <button className="btn btn-outline-success" type="submit">Search</button>
                    </form>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
