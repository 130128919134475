// import React, { useState } from 'react';
// import Navbar from './Navbar';
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle";

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Contact from './Contact';
import Gallery from './Gallery';
import About from './About';
import Register from './Register';
import Login from './Login';
import Navbar from './Navbar';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import './App.css'
import Threeone from './Threeone';



export default function App() {
  return (
    <>
    

    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/Contact' element={<Contact />} />
        <Route path='/Gallery' element={<Gallery />} />
        <Route path='/About' element={<About />} />
        <Route path='/Register' element={<Register />} />
        <Route path='/Login' element={<Login />} />
        <Route path='/Threeone' element={<Threeone />} />





      </Routes>
    </Router>
    
    
    
    
    </>
  )
}








// const App = () => {
//   const [searchQuery, setSearchQuery] = useState('');

//   const handleSearch = (query) => {
//     setSearchQuery(query);
//     // Handle search logic here (e.g., fetching data based on searchQuery)
//   };

//   return (
//     <div className="App">
//       <Navbar handleSearch={handleSearch} />
//       <div className="container mt-4">
//         <h2>Search Results</h2>
//         <p>Display search results or filtered content here...</p>
//         <p>Search Query: {searchQuery}</p>
//       </div>
//     </div>
//   );
// };

// export default App;
