import logo from './img/chatgpt1.png';
import title from './img/chatgpt1.png';
import Navbar from './Navbar';
export default function Home() {
    return (
        <>
        <div className='img1 text-center'>
            {/* <center><img src={title} className='img1' /></center> */}
            <h1>Chatgpt</h1>

            </div>

            <Navbar />





            <div class="navbar bg-dark navbar-dark">
                <div className="container-fluid">
                    <div className="navbar-brand">
                        <a href="/" className="logo-link">
                            <img src={logo} className='img' />
                        </a>
                    </div>
                    <ul class="nav">
                        <li className="nav-item"><a className="nav-link text-white" href="#">Best Sellers</a></li>
                        <li className="nav-item"><a className="nav-link text-white" href="#">Gift ideas</a></li>
                        <li className="nav-item"><a className="nav-link text-white" href="#">New Releases</a></li>
                        <li className="nav-item"><a className="nav-link text-white" href="#">Today's Deals</a></li>
                        <li className="nav-item"><a className="nav-link text-white" href="#">Customer Service</a></li>
                    </ul>
                </div>
            </div>
            <div className='bg'>
                {/* <ul class="nav">
                    <li className="nav-item"><a className="nav-link text-white" href="Contact">Contact</a></li>
                    <li className="nav-item"><a className="nav-link text-white" href="Gallery">Gallery</a></li>
                    <li className="nav-item"><a className="nav-link text-white" href="Register">Register</a></li>
                    <li className="nav-item"><a className="nav-link text-white" href="Login">Login</a></li>
                    <li className="nav-item"><a className="nav-link text-white" href="About">About</a></li>
                </ul> */}



            </div>
















        </>
    )
}