import Navbar from './Navbar';

export default function Register() {
    return(
        <>


        <center><h1>Register</h1></center>

        <Navbar />
        
        
        
        </>
    )
}