import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyA9wqY2WVgPXyS65KaOvGMNZAfa7m_g3XM",
  authDomain: "chatgpt-f691c.firebaseapp.com",
  projectId: "chatgpt-f691c",
  storageBucket: "chatgpt-f691c.appspot.com",
  messagingSenderId: "130904054388",
  appId: "1:130904054388:web:6f82146ffba450bb8d8f21"
};

firebase.initializeApp(firebaseConfig)

var db = firebase.firestore();
var storageRef = firebase.storage().ref();
var deletestorageRef = firebase.storage();
var auth = firebase.auth();
var provider = new firebase.auth.GoogleAuthProvider();


export { db, storageRef, deletestorageRef, auth, provider }