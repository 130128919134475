import { useEffect, useState } from "react";
import { auth, provider } from "./Firebase"
import firebase from "firebase";
import Navbar from './Navbar';



export default function Threeone() {


    function loginwithgoogle() {

        auth.signInWithPopup(provider)
            .then((result) => {
                /** @type {firebase.auth.OAuthCredential} */
                var credential = result.credential;

                // This gives you a Google Access Token. You can use it to access the Google API.
                var token = credential.accessToken;
                // The signed-in user info.
                var user = result.user;
                // IdP data available in result.additionalUserInfo.profile.
                // ...
            }).catch((error) => {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...
            });


    }


    const [userid, setuserid] = useState('');

    function checkuser() {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/v8/firebase.User
                var uid = user.uid;
                console.log(user);
                setuserid(user);
            } else {
                // User is signed out
                console.log(null);
            }
        });
    }
    useEffect(() => {
        checkuser();
    }, [])


    function Logoutuser() {
        auth.signOut().then((succ) => {
            checkuser();
            setuserid('');
        })
    }


    const [receivedotp, setreceivedotp] = useState(false);
    const [storereceivedotp, setstorereceivedotp] = useState("");


    function sendotpp(e) {
        e.preventDefault();
        var data = new FormData(e.currentTarget);

        var phoneNumber = "+91" + data.get("number");

        var appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');

        firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
            .then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                // window.confirmationResult = confirmationResult;
                e.target.reset();
                setreceivedotp(true);
                setstorereceivedotp(confirmationResult);
                // ...
            }).catch((error) => {
                // Error; SMS not sent
                // ...
            });


    }


    function fillotpp(e) {
        e.preventDefault();
        var data = new FormData(e.currentTarget);
        var code = data.get("otp");
        storereceivedotp.confirm(code).then((result) => {
            // User signed in successfully.
            const user = result.user;
            // ...
        }).catch((error) => {
            // User couldn't sign in (bad verification code?)
            // ...
            console.log(error);
        });
    }

    function fillemialpass(e) {
        e.preventDefault();
        var data = new FormData(e.currentTarget);
        var email = data.get("email");
        var password = data.get("password");

        firebase.auth().createUserWithEmailAndPassword(email, password)
            .then((userCredential) => {
                // Signed in 
                var user = userCredential.user;
                // ...
                alert("Ok");
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                // ..
            });
    }

    function fillemialpass2(e) {
        e.preventDefault();
        var data = new FormData(e.currentTarget);
        var email = data.get("email");
        var password = data.get("password");
        firebase.auth().signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                // Signed in
                var user = userCredential.user;
                // ...
                alert("Loged Id");
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log(errorCode);
                console.log(errorMessage);
                alert("Wrong Id or Password");
            });
    }


    return (

        
        <div className="h100 w-100 d-flex justify-content-center align-items-center">


            <Navbar />
            {userid ? (
                <div className="text-center p-5">

                    {userid.displayName}

                    <br />

                    <img src={userid.photoURL} />

                    <br />

                    <button onClick={Logoutuser} className="btn btn-info">Logout</button>

                </div>
            ) : (
                <div className="text-center">
                    <div className="card">
                        <div className="card-body">

                            <form onSubmit={fillemialpass}>
                                <h4>Register Email Password</h4>
                                <input type="email" name="email" placeholder="Fill your Email" className="form-control my-2" />
                                <input type="password" name="password" placeholder="Fill your Password" className="form-control my-2" />
                                <input type="submit" value={"Register"} className="btn btn-danger my-2" />
                            </form>
                            <hr />

                            <form onSubmit={fillemialpass2}>
                                <h4>Login Email Password</h4>
                                <input type="email" name="email" placeholder="Fill your Email" className="form-control my-2" />
                                <input type="password" name="password" placeholder="Fill your Password" className="form-control my-2" />
                                <input type="submit" value={"Register"} className="btn btn-danger my-2" />
                            </form>
                            <hr />


                            {receivedotp ? (
                                <form onSubmit={fillotpp}>
                                    <h4>Fill OTP</h4>
                                    <input type="number" name="otp" placeholder="Fill your OTP" className="form-control my-2" />
                                    <input type="submit" value={"Submit OTP"} className="btn btn-danger my-2" />
                                </form>
                            ) : (
                                <form onSubmit={sendotpp}>
                                    <h4>Send OTP</h4>
                                    <input type="number" name="number" placeholder="Fill your Number" className="form-control my-2" />
                                    <input type="submit" value={"Login"} className="btn btn-danger my-2" />
                                    <div id="recaptcha-container"></div>
                                </form>
                            )}
                        </div>
                    </div>
                    <br />


                    <button className="btn btn-danger" onClick={loginwithgoogle}>
                        Login with Google
                    </button>
                </div >
            )
            }

        </div >

        
    )
}